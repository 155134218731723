// extracted by mini-css-extract-plugin
export var carouselContainer = "G_wc d_w d_H d_bf d_Z";
export var carouselContainerCards = "G_wd G_wc d_w d_H d_bf d_Z";
export var carouselContainerSides = "G_wf d_w d_H d_Z";
export var prevCarouselItem = "G_wg d_w d_H d_0 d_k";
export var prevCarouselItemL = "G_wh G_wg d_w d_H d_0 d_k";
export var moveInFromLeft = "G_wj";
export var prevCarouselItemR = "G_wk G_wg d_w d_H d_0 d_k";
export var moveInFromRight = "G_wl";
export var selectedCarouselItem = "G_wm d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "G_wn G_wm d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "G_wp G_wm d_w d_H d_Z d_bf";
export var nextCarouselItem = "G_wq d_w d_H d_0 d_k";
export var nextCarouselItemL = "G_wr G_wq d_w d_H d_0 d_k";
export var nextCarouselItemR = "G_ws G_wq d_w d_H d_0 d_k";
export var arrowContainer = "G_wt d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "G_wv G_wt d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "G_ww G_wv G_wt d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "G_wx G_wt d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "G_wy";
export var nextArrowContainerHidden = "G_wz G_wx G_wt d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "G_kG d_0";
export var prevArrow = "G_wB G_kG d_0";
export var nextArrow = "G_wC G_kG d_0";
export var carouselIndicatorContainer = "G_wD d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "G_wF d_w d_bz d_bF";
export var carouselText = "G_wG d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "G_wH G_wG d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "G_wJ d_b7";
export var carouselIndicator = "G_wK G_wJ d_b7";
export var carouselIndicatorSelected = "G_wL G_wJ d_b7";
export var arrowsContainerTopRight = "G_wM d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "G_wN d_0 d_bl d_bC";
export var arrowsContainerSides = "G_wP d_0 d_bl d_bC";
export var smallArrowsBase = "G_wQ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "G_wR G_wQ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "G_wS G_wR G_wQ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "G_wT G_wQ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "G_wV G_wT G_wQ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "G_p9";
export var iconSmall = "G_wW";
export var multipleWrapper = "G_wX d_bC d_bF d_bf";
export var multipleImage = "G_wY d_bC";
export var sidesPrevContainer = "G_wZ G_wR G_wQ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "G_w0 G_wR G_wQ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";