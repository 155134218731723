// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "q_q5 d_bD";
export var datasheetRowWrapper = "q_lt d_lt d_bK";
export var datasheetLeftWrapper = "q_q6 d_bz d_bP";
export var datasheetWrapperFull = "q_q7 d_cD";
export var datasheetWrapperFullLeft = "q_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "q_mv d_lv";
export var contentWrapperCenter = "q_q8 d_lz";
export var datasheetLeftWrapperCenter = "q_q9 d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "q_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "q_rb d_lw";
export var datasheetFooter = "q_ly d_ly";
export var alignLeft = "q_qh d_dv";
export var alignCenter = "q_bP d_dw";
export var alignRight = "q_qj d_dx";
export var datasheetInnerWrapperNewLeft = "q_rc d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "q_rd d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "q_rf d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "q_rg d_bK";
export var datasheetSubtitle = "q_rh d_cx";
export var tableRow = "q_rj";
export var cell = "q_rk";
export var tableRowWrapper = "q_rl d_w d_dv";
export var tableHeadingCell = "q_rm";
export var tableHeading = "q_rn";
export var tableRowStriped = "q_rp";
export var tableRowHeading = "q_rq";
export var dataTable = "q_rr d_w";
export var imageWrapper = "q_rs d_fg d_Z";
export var imageWrapperFull = "q_rt d_H d_w d_bf d_Z";
export var imageWrapperIcon = "q_rv";
export var titleMargin = "q_rw d_cs";
export var datasheetInnerInnerWrapper = "q_rx d_w";
export var hasLabels = "q_ry";
export var label = "q_rz";
export var SmallSmall = "q_rB z_rB z_s8 z_tw";
export var SmallNormal = "q_rC z_rC z_s8 z_tx";
export var SmallLarge = "q_rD z_rD z_s8 z_tt";