// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_r5 d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_r6 d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_r7 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_r8 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_r9 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_sb d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_sc d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_sd z_sd";
export var heroExceptionNormal = "s_sf z_sf";
export var heroExceptionLarge = "s_sg z_sg";
export var Title1Small = "s_sh z_sh z_s8 z_s9";
export var Title1Normal = "s_sj z_sj z_s8 z_tb";
export var Title1Large = "s_sk z_sk z_s8 z_tc";
export var BodySmall = "s_sl z_sl z_s8 z_ts";
export var BodyNormal = "s_sm z_sm z_s8 z_tt";
export var BodyLarge = "s_sn z_sn z_s8 z_tv";