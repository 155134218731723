// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "v_sL d_gS d_cw d_dv";
export var quoteParagraphCenter = "v_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "v_gV d_gV d_cw d_dx";
export var quoteRowLeft = "v_sM d_bG";
export var quoteRowCenter = "v_sN d_bD";
export var quoteRowRight = "v_sP d_bH";
export var quoteWrapper = "v_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "v_gR d_gR";
export var quoteExceptionSmall = "v_sQ z_sQ";
export var quoteExceptionNormal = "v_sR z_sR";
export var quoteExceptionLarge = "v_sS z_sS";
export var quoteAuthorExceptionSmall = "v_sT z_sT";
export var quoteAuthorExceptionNormal = "v_sV z_sV";
export var quoteAuthorExceptionLarge = "v_sW z_sW";