// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "w_sX d_bD";
export var storyRowWrapper = "w_hx d_hx d_bK";
export var storyLeftWrapper = "w_sY d_bz d_bP";
export var storyWrapperFull = "w_sZ d_cD";
export var storyWrapperFullLeft = "w_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "w_mv d_hy";
export var storyLeftWrapperCenter = "w_s0 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "w_s1 d_hF";
export var storyHeader = "w_s2 d_hD d_w d_cs";
export var storyHeaderCenter = "w_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "w_hB d_hB d_by d_dw";
export var storyBtnWrapper = "w_s3 d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "w_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "w_rs d_fg d_Z";
export var imageWrapperFull = "w_rt d_w d_H d_bf d_Z";
export var SubtitleSmall = "w_qd z_qd z_s8 z_tl";
export var SubtitleNormal = "w_qf z_qf z_s8 z_tm";
export var SubtitleLarge = "w_qg z_qg z_s8 z_tn";
export var textLeft = "w_dv";
export var textCenter = "w_dw";
export var textRight = "w_dx";