// extracted by mini-css-extract-plugin
export var alignDiscLeft = "r_rF d_fp d_bG d_dv";
export var alignLeft = "r_qh d_fp d_bG d_dv";
export var alignDiscCenter = "r_rG d_fq d_bD d_dw";
export var alignCenter = "r_bP d_fq d_bD d_dw";
export var alignDiscRight = "r_rH d_fr d_bH d_dx";
export var alignRight = "r_qj d_fr d_bH d_dx";
export var footerContainer = "r_rJ d_dW d_bW";
export var footerContainerFull = "r_rK d_dT d_bW";
export var footerHeader = "r_kf d_kf";
export var footerTextWrapper = "r_rL d_w";
export var footerDisclaimerWrapper = "r_km d_km d_ck";
export var badgeWrapper = "r_rM d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "r_rN d_bz d_bJ d_bN d_bL";
export var wide = "r_rP d_cy";
export var right = "r_rQ d_bK";
export var line = "r_fk d_fl d_cv";
export var badgeDisclaimer = "r_rR d_bC d_bP d_bJ";
export var badgeContainer = "r_rS d_bz d_bH d_bP";
export var badge = "r_rT";
export var padding = "r_rV d_c7";
export var end = "r_rW d_bH";
export var linkWrapper = "r_rX d_dB";
export var link = "r_mC d_dB";
export var colWrapper = "r_rY d_cx";
export var consent = "r_f d_f d_bC d_bP";
export var disclaimer = "r_rZ d_bz d_bJ";
export var media = "r_r0 d_bx d_dy";
export var itemRight = "r_r1";
export var itemLeft = "r_r2";
export var itemCenter = "r_r3";
export var exceptionWeight = "r_r4 z_tB";